import { render, staticRenderFns } from "./filter-side-bar-item.vue?vue&type=template&id=73df97da"
import script from "./filter-side-bar-item.vue?vue&type=script&lang=ts"
export * from "./filter-side-bar-item.vue?vue&type=script&lang=ts"
import style0 from "./filter-side-bar-item.vue?vue&type=style&index=0&id=73df97da&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_css-loader@6.10.0_lodash@4.17.21_prettier@2.8.8__7m7nazr4vhqxtxsn73cpxb4uby/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports