<template>
  <div class="filter-side-bar-item" :class="{ unavailable: !available }">
    <bce-button
      v-b-toggle="collapsible"
      block
      :color="active ? 'secondary' : 'ghost'"
      @click="open = !open"
    >
      <div>
        <bce-icon :raw="icon || getIcon(filter.element.type)" />

        <span>
          {{ filter.label }}
        </span>

        <bce-icon
          v-if="$user.admin && hidable"
          fixed-width
          :raw="available ? 'fas:check' : 'fas:times'"
          @click.stop="setAvailability(!available)"
        />
        <bce-icon
          v-else
          fixed-width
          :raw="open ? 'fas:caret-up' : 'fas:caret-down'"
        />
      </div>
    </bce-button>

    <b-collapse v-model="open" :class="`options option-${filter.element.type}`">
      <p v-if="filter.element.question" class="question">
        {{ filter.element.question }}
      </p>

      <div v-if="filter.element.type === 'matrix'">
        <bce-element
          ref="matrix"
          allow-null
          style="margin: 0 16px"
          :template.prop="matrixTemplate"
          type="matrix"
          @input="matrixChange($event.target.value)"
        />

        <br />
        <div
          v-for="(row, i) of filter.element.value.rows"
          :key="i"
          style="margin: 0 16px"
        >
          {{ i + 1 }}. {{ row.text }}
        </div>
        <br />
      </div>

      <template v-else-if="filter.element.type === 'slider'">
        <VueSlider
          class="vue-slider"
          :enable-cross="false"
          :max="parseInt(filter.element.value.max, 10)"
          :min="filter.element.value.min"
          tooltip="always"
          :value="getValue()"
          @change="debounceSliderChange($event)"
        />

        <bce-button color="secondary" design="text" @click="remove()">
          {{ $t('reset', { item: '' }) }}
        </bce-button>
      </template>

      <template v-else>
        <bce-chip
          v-for="(value, i) of filter.element.value"
          :key="i"
          :checked="getValue(i)"
          color="secondary"
          :icon="filter.element.type === 'icon' && value.text"
          :type="multiselect ? 'filter' : 'choice'"
          @click="
            $event.target.checked ? add(i, value.name || value.text) : remove(i)
          "
        >
          {{ value.text }}
        </bce-chip>
      </template>
    </b-collapse>
  </div>
</template>

<script lang="ts">
import 'vue-slider-component/theme/default.css';

import { Filter } from '@app/models';
import { debounce } from '@bcase/core';
import { BlockElement } from '@bcase/module-editor';
import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import VueSlider from 'vue-slider-component';
import { getModule } from 'vuex-module-decorators';

import { StoredFilter } from '../../models/stored-filter';
import { ResearchModule } from '../../store/modules/research-module';
import { ResultModule } from '../../store/modules/result-module';

@Component({
  components: { VueSlider },
})
export default class FilterSideBarItem extends Vue {
  @Prop()
  public check?: (filter: Filter) => StoredFilter['check'];

  @Prop()
  public filter!: Filter;

  @Prop()
  public hidable?: boolean;

  @Prop()
  public icon?: string;

  @Prop()
  public multi?: boolean;

  @Ref('matrix')
  private matrix?: HTMLBceElementMatrixElement;

  public debounceSliderChange = debounce(this.sliderChange, 500);
  public open = false;

  private research = getModule(ResearchModule);
  private result = getModule(ResultModule);

  public get available() {
    return (
      this.research.current?.filters?.[this.filter.id] ?? !this.filter.admin
    );
  }

  public get active() {
    return !!this.result.filters.find(f => f.id === this.filter.id);
  }

  public get matrixTemplate() {
    if (this.filter.element.type !== 'matrix') return {};

    return {
      columns: this.filter.element.value.columns,
      rows: this.filter.element.value.rows.map((_: any, i: number) => ({
        text: '' + (i + 1),
      })),
    };
  }

  public get matrixValue() {
    if (this.filter.element.type !== 'matrix') return [];

    const base = this.filter.element.value.rows.map(() => null);
    const filter = this.result.filters.find(f => f.id === this.filter.id);
    if (!filter) return base;

    for (const option of filter.options) {
      const [rowStr, colStr] = (option.value as string).split(',');
      const row = parseInt(rowStr, 10) - 1;
      const col = parseInt(colStr, 10);
      base[row] = col;
    }

    return base;
  }

  public get multiselect() {
    if (this.multi != undefined) return this.multi;

    switch (this.filter.element.type) {
      case 'checkbox':
      case 'matrix':
        return true;
      default:
        return false;
    }
  }

  public get collapsible() {
    return `${this.filter.id}-collapsible`;
  }

  @Watch('matrixValue')
  public watchMatrixValue() {
    if (this.matrix) this.matrix.value = this.matrixValue;
  }

  public add(index: number | number[], name?: string) {
    const cur = this.result.filters.find(f => f.id === this.filter.id);

    const option: StoredFilter['options'][number] = {
      active: true,
      name: Array.isArray(index) ? index.join(' - ') : name || '',
      value: Array.isArray(index) ? index.join(',') : index,
    };

    const options =
      this.multiselect && cur
        ? [...cur.options, option].sort((a: any, b: any) => a.value - b.value)
        : [option];

    this.result.filterAdd({
      category: this.filter.label,
      check: this.check
        ? this.check(this.filter)
        : this.result.checkCustom(this.filter).bind(this.result),
      fsqFilters: this.research.fsqFilters,
      id: this.filter.id,
      options,
    });
  }

  public getIcon(type: BlockElement.Type) {
    return BlockElement.icon(type);
  }

  public getValue(index?: number) {
    if (this.filter.element.type === 'slider') {
      const filter = this.result.filters.find(f => f.id === this.filter.id);
      if (filter) return (filter.options[0].value as string).split(',');

      const { min, max } = this.filter.element.value;
      return [min, max];
    }

    return !!this.result.filters.find(f => {
      return f.id === this.filter.id && f.options.find(o => o.value === index);
    });
  }

  public matrixChange(value: (number | null)[]) {
    for (let row = 0; row < value.length; row++) {
      // Reset row filter
      for (let col = 0; col < this.filter.element.value.columns.length; col++)
        this.remove([row + 1, col]);

      // Add new filter
      const column = value[row];
      if (column != null) this.add([row + 1, column]);
    }
  }

  public remove(index?: number | number[]) {
    this.result.filterRemove({
      id: this.filter.id,
      index: Array.isArray(index) ? index.join(',') : index,
    });
  }

  public async setAvailability(available: boolean) {
    if (!this.research.current) return;

    await this.$firebase
      .doc(`research/${this.research.current.id}`)
      .update(`filters.${this.filter.id}`, available);
  }

  public sliderChange(value: number[]) {
    const { min, max } = this.filter.element.value;
    if (value[0] === min && value[1] === max) this.remove();
    else this.add(value);
  }
}
</script>

<style lang="scss">
.filter-side-bar-item {
  width: 100%;

  &.unavailable {
    opacity: 0.5;
  }

  > bce-button {
    @include elevate(0);
    border-radius: 0;
    margin: 0;

    div {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  .vue-slider {
    margin: 34px 20px 4px;

    .vue-slider-dot-tooltip-inner {
      background-color: bce-color(secondary);
      border-color: bce-color(secondary);
      color: bce-color-on(secondary);
    }

    .vue-slider-process {
      background-color: bce-color(secondary);
    }
  }

  .option-slider {
    text-align: right;
  }

  .options {
    bce-chip {
      margin: 0 10px;
      width: calc(100% - 20px);
      text-overflow: ellipsis;
    }

    .question {
      margin: 16px;
      text-align: center;
      color: bce-color-on(surface);
    }
  }
}
</style>
