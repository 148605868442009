import { CmsAsset } from '@app/models';
import { Route, RouteConfig } from 'vue-router';
import { getModule } from 'vuex-module-decorators';

import { DashboardModule } from '../store/modules/dashboard-module';
import { firebase } from '../utils/firebase';
import { auth } from './core';

export type CustomRouteConfig = RouteConfig & {
  children?: CustomRouteConfig[];
  meta?: { auth?: boolean; admin?: boolean; permission?: string[] };
};

export const routes: CustomRouteConfig[] = [
  {
    name: '_invoice',
    path: '/_invoice',
    component: () => import('../views/_invoice.vue'),
  },
  {
    name: 'about',
    path: '/about',
    component: () => import('../views/general/about.vue'),
  },
  {
    name: 'admin-company-create',
    path: '/admin/company-create',
    component: () => import('../views/admin/admin-company-create.vue'),
    meta: { admin: true },
  },
  {
    name: 'admin-company-management',
    path: '/admin/company-management',
    component: () => import('../views/admin/admin-company-management.vue'),
    meta: { admin: true },
  },
  {
    name: 'admin-global-dashboard',
    path: '/admin/dashboard',
    component: () => import('../views/admin/admin-global-dashboard.vue'),
    meta: { admin: true },
  },
  {
    name: 'admin-role-management',
    path: '/admin/role-management',
    component: () => import('../views/admin/admin-role-management.vue'),
    meta: { admin: true },
  },
  {
    name: 'admin-respondent',
    path: '/admin/respondent',
    component: () => import('../views/admin/admin-respondent.vue'),
    meta: { auth: true, permission: ['respondent'] },
  },
  {
    name: 'admin-task',
    path: '/admin/task',
    component: () => import('../views/admin/admin-task.vue'),
    meta: { admin: true },
  },
  {
    name: 'filter-management',
    path: '/filter-management',
    component: () => import('../views/filter-management.vue'),
    meta: { admin: true },
  },
  {
    name: 'account',
    path: '/account',
    component: () => import('../views/account.vue'),
    redirect: '/account/settings',
    children: [
      {
        name: 'account-password',
        path: 'password',
        component: () => import('../views/account/account-password.vue'),
        meta: { auth: true },
      },
      {
        name: 'account-settings',
        path: 'settings',
        component: () => import('../views/account/account-settings.vue'),
        meta: { auth: true },
      },
    ],
  },
  {
    name: 'account-verified',
    path: '/account/verified',
    component: () => import('../views/account/account-verified.vue'),
  },
  {
    name: 'benefits',
    path: '/benefits',
    component: () => import('../views/general/benefits.vue'),
  },
  {
    name: 'company',
    path: '/c',
    component: () => import('../views/company.vue'),
    children: [
      {
        name: 'company-roles',
        path: ':company_id?/roles',
        component: () => import('../views/company/company-roles.vue'),
        meta: { auth: true, permission: ['company-roles'] },
      },
      {
        name: 'company-settings',
        path: ':company_id?/settings',
        component: () => import('../views/company/company-settings.vue'),
        meta: { auth: true, permission: ['company-settings'] },
      },
      {
        name: 'company-users',
        path: ':company_id?/users',
        component: () => import('../views/company/company-users.vue'),
        meta: { auth: true, permission: ['company-users'] },
      },
    ],
  },
  {
    path: '/dashboard/:id',
    component: () => import('../views/dashboard.vue'),
    children: [
      {
        name: 'dashboard-all-results',
        path: 'result',
        component: () => import('../views/dashboard/dashboard-all-results.vue'),
        meta: { auth: true, permission: ['dashboard-all-results'] },
      },
      {
        name: 'dashboard-overview',
        path: '',
        component: () => import('../views/dashboard/dashboard-overview.vue'),
        meta: { auth: true, permission: ['dashboard'] },
      },
      {
        name: 'dashboard-result',
        path: 'result/:eid',
        component: () => import('../views/dashboard/dashboard-result.vue'),
        meta: { auth: true, permission: ['dashboard'] },
      },
    ],
  },
  {
    name: 'dashboard-intro',
    path: '/dashboard-intro',
    component: () => import('../views/general/dashboard-intro.vue'),
  },
  {
    name: 'editor',
    path: '/editor',
    component: () => import('../views/editor.vue'),
    meta: { admin: true },
  },
  {
    name: 'editor-service',
    path: '/editor/service/:id?',
    component: () => import('../views/editor/editor-service.vue'),
    meta: { admin: true },
  },
  {
    name: 'editor-module',
    path: '/editor/:module',
    component: () => import('../views/editor/editor-module.vue'),
    meta: { admin: true },
  },
  {
    name: 'editor-block',
    path: '/editor/:module/:block',
    component: () => import('../views/editor/editor-block.vue'),
    meta: { admin: true },
  },
  {
    name: 'faq',
    path: '/faq',
    component: () => import('../views/general/faq.vue'),
  },
  {
    name: 'index',
    path: '/',
    component: () => import('../views/index.vue'),
  },
  {
    name: 'instructions',
    path: '/instructions',
    component: () => import('../views/general/instructions.vue'),
  },
  {
    name: 'invitation',
    path: '/invitation',
    component: () => import('../views/invitation.vue'),
  },
  {
    name: 'method',
    path: '/method',
    component: () => import('../views/general/method.vue'),
  },
  {
    name: 'oauth',
    path: '/oauth',
    component: () => import('../views/general/oauth.vue'),
  },
  {
    name: 'on-boarding',
    path: '/on-boarding',
    component: () => import('../views/on-boarding.vue'),
    children: [
      {
        name: 'on-boarding-account',
        path: 'account',
        component: () => import('../views/account/account-settings.vue'),
        meta: { auth: true },
      },
      {
        name: 'on-boarding-company',
        path: 'company',
        component: () => import('../views/company/company-settings.vue'),
        meta: { auth: true },
      },
    ],
  },
  {
    name: 'privacy',
    path: '/privacy',
    beforeEnter: async (_, from) => {
      const url = await getCmsAsset('Privacy Policy');
      if (from.name) window.location.href = url;
      else window.location.replace(url);
    },
  },
  {
    name: 'quick-start',
    path: '/quick-start',
    component: () => import('../views/quick-start.vue'),
  },
  {
    path: '/c/:company_id/research',
    component: () => import('../views/research.vue'),
    beforeEnter: (to: Route, from: Route, next: Function) => {
      const dash = getModule(DashboardModule);
      dash.unbind();
      next();
    },
    children: [
      {
        name: 'research-overview',
        path: '',
        component: () => import('../views/research/research-overview.vue'),
        meta: { auth: true, permission: ['research-overview'] },
      },
      { name: 'research-start', path: ':id' },
      {
        name: 'research-details-a',
        path: ':id/1a',
        component: () => import('../views/research/research-details-a.vue'),
        meta: { auth: true, permission: ['research-overview'] },
      },
      {
        name: 'research-details-b',
        path: ':id/1b',
        component: () => import('../views/research/research-details-b.vue'),
        meta: { auth: true, permission: ['research-overview'] },
      },
      {
        name: 'research-details-c',
        path: ':id/1c',
        component: () => import('../views/research/research-details-c.vue'),
        meta: { auth: true, permission: ['research-overview'] },
      },
      {
        name: 'research-structure',
        path: ':id/2/:prop?',
        component: () => import('../views/research/research-structure.vue'),
        meta: { auth: true, permission: ['research-overview'] },
      },
      {
        name: 'research-example',
        path: ':id/2/:prop?',
        component: () => import('../views/research/research-example.vue'),
        meta: { auth: true, permission: ['research-overview'] },
      },
      {
        name: 'research-invitation',
        path: ':id/3',
        component: () => import('../views/research/research-invitation.vue'),
        meta: { auth: true, permission: ['research-overview'] },
      },
      {
        name: 'research-introduction',
        path: ':id/4',
        component: () => import('../views/research/research-introduction.vue'),
        meta: { auth: true, permission: ['research-overview'] },
      },
      {
        name: 'research-formula',
        path: ':id/5',
        component: () => import('../views/research/research-formula.vue'),
        meta: { auth: true, permission: ['research-overview'] },
      },
      {
        name: 'research-reminder',
        path: ':id/6',
        component: () => import('../views/research/research-reminder.vue'),
        meta: { auth: true, permission: ['research-overview'] },
      },
      {
        name: 'research-respondents',
        path: ':id/7',
        component: () => import('../views/research/research-respondents.vue'),
        meta: { auth: true, permission: ['research-overview'] },
      },
      {
        name: 'research-summary',
        path: ':id/8/:prop?',
        component: () => import('../views/research/research-summary.vue'),
        meta: { auth: true, permission: ['research-summary'] },
      },
    ],
  },
  {
    name: 'research-summary-2',
    path: '/research-summary/:id',
    component: () => import('../views/research/research-summary.vue'),
    meta: { auth: true, permission: ['research-summary'] },
  },
  {
    name: 'respondent',
    path: '/respondent',
    component: () => import('../views/respondent.vue'),
    children: [
      {
        name: 'complete-research',
        path: ':module/:block/:progress?',
        component: () => import('../views/respondent/respondent-research.vue'),
      },
    ],
  },
  {
    name: 'sign-in',
    path: '/sign-in',
    beforeEnter(to) {
      const email = to.query.email ? `&email=${to.query.email}` : '';
      window.location.replace(auth.createURL('/') + email);
    },
  },
  {
    name: 'solutions',
    path: '/solutions',
    component: () => import('../views/general/solutions.vue'),
  },
  {
    name: 'stories',
    path: '/stories',
    component: () => import('../views/general/stories.vue'),
  },
  {
    name: 'terms-and-conditions',
    path: '/terms-and-conditions',
    beforeEnter: async (_, from) => {
      const url = await getCmsAsset('Terms and Conditions');
      if (from.name) window.location.href = url;
      else window.location.replace(url);
    },
  },
  {
    name: 'contact',
    path: '/contact',
    component: () => import('../views/general/contact.vue'),
  },
  {
    name: 'concept',
    path: '/concept',
    component: () => import('../views/general/concept.vue'),
  },
  {
    name: 'unauthorized',
    path: '/unauthorized',
    component: () => import('../views/general/unauthorized.vue'),
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/general/404.vue'),
  },
];

const getCmsAsset = async (title: string) => {
  return firebase
    .col('@service/i18n/i18n-asset')
    .where('title', '==', title)
    .get()
    .then(snap => firebase.toData<CmsAsset>(snap))
    .then(assets => assets[0] && assets[0].url);
};
