import { Log, LogMap, LogType } from '@app/models';
import { AuthService } from '@bcase/auth';
import fb from 'firebase/app';
import Vue, { PluginFunction } from 'vue';
import { Component } from 'vue-property-decorator';

import FormuleBoosterLogo from '../components/0-formule-booster/formule-booster-logo.vue';
import { CompanyModule, company } from '../store/modules/company-module';
import { user, UserModule } from '../store/modules/user-module';
import { firebase } from './firebase';

const config = webpack.CONFIG;

export const auth = new AuthService({
  ...config.auth,
  redirect_uri: location.origin + new URL(config.auth.redirect_uri).pathname,
});

type AlertOptions = Parameters<HTMLBceRootElement['alert']>[2];
type ConfirmOptions = Parameters<HTMLBceRootElement['confirm']>[2];
type PromptOptions = Parameters<HTMLBceRootElement['prompt']>[3];

@Component
export class CoreMixin extends Vue {
  public get $app() {
    return {
      loader: (title = '', message = this.$t('loader-message-1')) => {
        return this.$root.$emit('app:loader', title, message);
      },
    };
  }

  public get $auth() {
    return auth;
  }

  public get $bce() {
    return {
      ...window.BCE,
      alert: (title: string, message: string, opt?: AlertOptions) => {
        const root = this.$root.$el as HTMLBceRootElement;
        return root.alert(title, message, opt);
      },
      confirm: (title: string, message: string, opt?: ConfirmOptions) => {
        const root = this.$root.$el as HTMLBceRootElement;
        return root.confirm(title, message, opt);
      },
      message: (text: string, duration?: number, color?: string) => {
        const root = this.$root.$el as HTMLBceRootElement;
        return root.message(text, { duration, color });
      },
      prompt: (
        title: string,
        message: string,
        label: string,
        opt?: PromptOptions,
      ) => {
        const root = this.$root.$el as HTMLBceRootElement;
        return root.prompt(title, message, label, opt);
      },
    };
  }

  public get $customerNameLong(): string {
    return webpack.CUSTOMER;
  }

  public get $customerNameShort(): string {
    return webpack.FEATURES.name_short;
  }

  public get $contentEditable() {
    return this.$store && this.$store.getters['content-editor/contentEditable'];
  }

  public get $firebase() {
    return firebase;
  }

  public get $signInURL() {
    const redirect =
      this.$route.name === 'unauthorized' &&
      this.$route.query &&
      (this.$route.query.redirect as string);
    const state = redirect || location.pathname + location.search;

    return auth.createURL(state);
  }

  public get $company(): CompanyModule {
    return company;
  }

  public get $routeConsole(): boolean {
    const routes = ['index', 'solutions', 'benefits', 'stories'];
    return routes.indexOf(this.$route.name || '') < 0;
  }

  public get $targetTabs() {
    return webpack.FEATURES.target.reduce((acc: string[], t: string) => {
      const target = t.split('-')[0];
      return acc.includes(target) ? acc : [...acc, target];
    }, []);
  }

  public get $user(): UserModule {
    return user;
  }

  public get $FEATURES() {
    return webpack.FEATURES;
  }

  public $createLog<T extends LogType>(type: T, data: LogMap[T]) {
    return {
      data,
      dateTime: fb.firestore.Timestamp.now(),
      id: this.$firebase.generateId(),
      type,
      user: this.$user.id,
      userEmail: this.$user.data?.email ?? '',
    } as Log;
  }
}

const install: PluginFunction<{}> = async _Vue => {
  _Vue.mixin(CoreMixin);
  _Vue.component('FormuleBoosterLogo', FormuleBoosterLogo);
};

export default { install };
