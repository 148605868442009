import {
  AppFilters,
  DashboardResult,
  DashboardView,
  Filter,
  Result,
} from '@app/models';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { ResultMap, StoredFilter } from '../../models/stored-filter';
import { parseResult } from '../../utils/dashboard-data-filter';
import { firebase } from '../../utils/firebase';
import { store } from '../store';
import { company } from './company-module';
import { user } from './user-module';

@Module({ dynamic: true, store, name: 'result', namespaced: true })
export class ResultModule extends VuexModule {
  private _app_dashboard?: DashboardView = undefined;
  private _app_filters?: AppFilters = undefined;
  private _filters: StoredFilter[] = [];
  private _loading = true;
  private _min: number = 0;
  private _researchId: string = '';
  private _comparisonId: string = '';
  private _comparisonResults: Result[] = [];
  private _results: Result[] = [];
  private _show_filters = false;
  private _target = '';

  public get all() {
    return this.respondentMinReached || user.admin
      ? this.allWithoutMinimum
      : [];
  }

  public get comparisonDashboard() {
    const results = filterResults(this, this._comparisonResults);
    const dashboardMap = results.reduce<{ [element: string]: DashboardResult }>(
      (acc, result) => {
        const id = result.elementId;
        acc[id] = parseResult(result, acc[id]);
        return acc;
      },
      {},
    );

    return Object.values(dashboardMap);
  }

  public get allWithoutMinimum() {
    return filterResults(this, this._results);
  }

  public get availableFilters() {
    return this._app_filters?.filters ?? [];
  }

  public get checkCustom() {
    return (filter: Filter): StoredFilter['check'] => {
      return (result, results) => {
        const stored = this.filters.find(f => f.id === filter.id);
        if (!stored) return false;

        const options = stored.options.filter(o => o.active);
        if (!options.length) return true;

        const indexes = options.map(o => o.value);
        const question =
          results.question.get(filter.element.id) ||
          results.question.get(filter.element.id + 'E') ||
          results.question.get(filter.element.id + 'H') ||
          [];

        switch (filter.element.type) {
          case 'checkbox': {
            const respondents = question
              .filter(r => {
                return indexes.every(i => {
                  return r.answer.value.some(
                    (option: any) => option.index === i,
                  );
                });
              })
              .map(r => r.respondent.user_id);
            return respondents.indexOf(result.respondent.user_id) >= 0;
          }
          case 'icon': {
            const respondents = question
              .filter(r => indexes.some(i => r.answer.value === i))
              .map(r => r.respondent.user_id);
            return respondents.indexOf(result.respondent.user_id) >= 0;
          }
          case 'matrix': {
            const respondents = question
              .filter(r =>
                indexes.every(i => {
                  const [rowStr, columnStr] = (i as string).split(',');
                  const row = parseInt(rowStr, 10) - 1;
                  const column = parseInt(columnStr, 10);
                  return r.answer.value[row] === column;
                }),
              )
              .map(r => r.respondent.user_id);
            return respondents.indexOf(result.respondent.user_id) >= 0;
          }
          case 'slider': {
            const [min, max] = (indexes[0] as string)
              .split(',')
              .map(v => parseInt(v, 10));
            const respondents = question
              .filter(r => r.answer.value >= min && r.answer.value <= max)
              .map(r => r.respondent.user_id);
            return respondents.indexOf(result.respondent.user_id) >= 0;
          }
          case 'radio': {
            const respondents = question
              .filter(r => indexes.some(i => r.answer.value.index === i))
              .map(r => r.respondent.user_id);
            return respondents.indexOf(result.respondent.user_id) >= 0;
          }
          default:
            console.warn('[result-module] Unimplemented filter', filter);
            return true;
        }
      };
    };
  }

  public get dashboard() {
    // Convert results to dashboard results, grouped by result id
    const data = this.all.reduce<{ [element: string]: DashboardResult }>(
      (acc, result) => {
        const id = result.elementId;
        acc[id] = parseResult(result, acc[id]);
        return acc;
      },
      {},
    );

    // Sort by order of occurrence
    return Object.values(data).sort((a, b) => {
      // Older results don't have an order property, use the old (broken)
      // sorting for backwards compatibility
      if (!a.order || !b.order) {
        const [a1, a2] = a.nr.split('.').map(n => parseInt(n, 10));
        const [b1, b2] = b.nr.split('.').map(n => parseInt(n, 10));
        return a1 === b1 ? a2 - b2 : a1 - a2;
      }

      // Sort using module order, block depth, and element index
      const { module: m1, block: b1, element: e1 } = a.order;
      const { module: m2, block: b2, element: e2 } = b.order;
      if (m1 !== m2) return m1 - m2;
      if (b1 !== b2) return b1 - b2;
      return e1 - e2;
    });
  }

  public get dashboardLayout() {
    return this._app_dashboard || {};
  }

  public get filterRoutes() {
    return [
      'dashboard',
      'dashboard-overview',
      'dashboard-result',
      'dashboard-all-results',
    ];
  }

  public get filters() {
    return this._filters;
  }

  public get loading() {
    return this._loading;
  }

  public get researchId() {
    return this._researchId;
  }

  public get researchMinimumResults() {
    return this._min;
  }

  public get respondentMinReached() {
    return this.respondentCount >= this.researchMinimumResults;
  }

  public get respondentCount() {
    const respondents = this.allWithoutMinimum.reduce((set, result) => {
      if (result.respondent) set.add(result.respondent.user_id);
      return set;
    }, new Set<string>());

    return respondents.size;
  }

  public get respondentFilters() {
    return (this._app_filters && this._app_filters.respondentFilters) || [];
  }

  public get respondents() {
    const set = this.all.reduce((acc, val) => {
      acc.add(val.respondent.user_id);
      return acc;
    }, new Set<string>());

    return Array.from(set);
  }

  public get showFilters() {
    return this._show_filters;
  }

  public get target() {
    return this._target;
  }

  @Action({ rawError: true })
  public async bind(researchId?: string) {
    this.LOADING(true);

    if (this.researchId && this.researchId !== researchId)
      await firebase.unbind(this, '_results');

    const filterRef = firebase.doc('app/filters');
    await firebase.bind(this, '_app_filters', filterRef);

    const dashboardRef = firebase.doc('app/dashboard');
    await firebase.bind(this, '_app_dashboard', dashboardRef);

    if (researchId && this.researchId !== researchId) {
      this.RESEARCH(researchId);
      this.TARGET('');
      const ref = firebase.col('results').where('rid', '==', researchId);
      await firebase.bind(this, '_results', ref);
      if (this.all.length) this.TARGET(this.all[0].target.split('-')[0]);
    }

    this.LOADING(false);
  }

  @Action({ rawError: true })
  public async compare(comparisonId: string) {
    this.LOADING(true);

    if (!comparisonId) await firebase.unbind(this, '_comparisonResults');
    else if (this._comparisonId !== comparisonId) {
      const ref = firebase.col('results').where('rid', '==', comparisonId);
      await firebase.bind(this, '_comparisonResults', ref);
    }

    this.COMPARE(comparisonId);
    this.LOADING(false);
  }

  @Action({ rawError: true })
  public async filterAdd(payload: StoredFilter & { fsqFilters?: Filter[] }) {
    // Replace existing or add new
    const existing = this.filters.find(f => payload.id === f.id);
    const filters = existing
      ? this.filters.map(f => (f.id === payload.id ? payload : f))
      : [...this.filters, payload];

    // Use same sorting as available filters (admin defined)
    const HARDCODED = ['filter-module', 'filter-gender'];
    const order = [
      ...HARDCODED,
      ...(payload.fsqFilters?.map(f => f.id) ?? []),
      ...this.availableFilters.map(f => f.id),
      ...this.respondentFilters.map(f => `respondent-${f}`),
    ];

    const sorted = order
      .map(id => filters.find(filter => filter.id === id))
      .filter(Boolean) as StoredFilter[];

    this.FILTERS(sorted);
  }

  @Action({ rawError: true })
  public async filterRemove(payload: { id: string; index?: number | string }) {
    const filters = this.filters
      .map(f => {
        if (f.id !== payload.id) return f;

        const options = payload.index
          ? f.options.filter(o => payload.index !== o.value)
          : [];
        return { ...f, options };
      })
      .filter(f => f.options.length);

    this.FILTERS(filters);
  }

  @Action({ rawError: true })
  public async filterReset() {
    this.FILTERS([]);
  }

  @Action({ rawError: true })
  public async filterShow(payload?: boolean) {
    this.FILTERS_SHOW(payload == undefined ? !this.showFilters : payload);
  }

  @Action({ rawError: true })
  public async filterToggle(payload: {
    active?: boolean;
    id: string;
    index?: number;
  }) {
    const filters = this.filters.map(filter => {
      if (filter.id !== payload.id) return filter;

      const options = filter.options.map(option => {
        if (payload.index != null && option.value !== payload.index)
          return option;

        const active = payload.active == null ? !option.active : payload.active;
        return { ...option, active };
      });

      return { ...filter, options };
    });

    this.FILTERS(filters);
  }

  @Action({ rawError: true })
  public async setMinimum(min: number) {
    this.MINIMUM(min);
  }

  @Action({ rawError: true })
  public async setTarget(target: string) {
    this.TARGET(target);
  }

  @Action({ rawError: true })
  public async unbind() {
    await firebase.unbind(this, '_app_filters');
    await firebase.unbind(this, '_results');
    this.FILTERS([]);
    this.LOADING(true);
    this.RESEARCH('');
    this.TARGET('');
  }

  @Mutation
  public COMPARE(comparisonId: string) {
    this._comparisonId = comparisonId;
  }

  @Mutation
  public FILTERS(filters: StoredFilter[]) {
    this._filters = filters;
  }

  @Mutation
  public FILTERS_SHOW(show: boolean) {
    this._show_filters = show;
  }

  @Mutation
  public LOADING(loading: boolean) {
    this._loading = loading;
  }

  @Mutation
  public MINIMUM(min: number) {
    this._min = min;
  }

  @Mutation
  public RESEARCH(researchId: string) {
    this._researchId = researchId;
  }

  @Mutation
  public TARGET(target: string) {
    this._target = target;
  }
}

function filterResults(
  context: { target: string; filters: StoredFilter[] },
  results: Result[],
) {
  const { access } = company;

  const grouped = results.reduce<ResultMap>(
    (acc, result) => {
      const question = acc.question.get(result.elementId);
      const questions = question ? [...question, result] : [result];
      acc.question.set(result.elementId, questions);

      const respondent = acc.respondent.get(result.respondent.user_id);
      const respondents = respondent ? [...respondent, result] : [result];
      acc.respondent.set(result.respondent.user_id, respondents);

      return acc;
    },
    { respondent: new Map(), question: new Map() },
  );

  return results.filter(result => {
    if (context.target && !result.target.startsWith(context.target))
      return false;

    // Apply user filter
    if (context.filters.some(filter => !filter.check(result, grouped)))
      return false;

    // Apply access filter
    return (
      user.admin ||
      Object.keys(access).every(key => {
        return key in (result.respondentData || {}) &&
          !!result.respondentData[key]
          ? access[key].indexOf(result.respondentData[key]) >= 0
          : true;
      })
    );
  });
}
