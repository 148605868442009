<template>
  <div id="footer">
    <div class="container">
      <div v-if="!$routeConsole" class="row">
        <div class="col-md-4">
          <div v-html="$t('home-footer-column-1')" />
          <br />
          <br />
          <router-link to="/terms-and-conditions">
            {{ $t('terms-and-conditions') }}
          </router-link>
          <br />
          <router-link to="/privacy">{{ $t('privacy-statement') }}</router-link>
          <br />
          <br />
        </div>
        <div class="col-md-4">
          <div v-html="$t('home-footer-column-2')" />
          <br />
          <br />
        </div>
        <div class="col-md-4">
          <div v-html="$t('home-footer-column-3')" />
        </div>
      </div>
    </div>
    <footer>
      <img
        :src="
          $routeConsole
            ? require('@app/asset/logo-grey.png')
            : require('@app/asset/logo-color.png')
        "
        alt=""
      />
    </footer>
    <div v-if="$user.admin" class="version">{{ version }}</div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class AppFooter extends Vue {
  public get version() {
    return `v${webpack.VERSION}-${webpack.BUILD_DATE}`;
  }
}
</script>

<style lang="scss">
#footer {
  position: relative;
  width: 100%;

  .version {
    position: absolute;
    right: 16px;
    top: 8px;
    color: bce-color-on(surface, 4);
    user-select: none;
  }

  @include media-medium {
    .version {
      left: 0px;
      top: initial;
      bottom: 0px;
    }
  }

  footer {
    text-align: center;
    width: 100%;
    color: bce-color-on(surface, 4);

    img {
      width: 200px;
      opacity: 0.2;
      padding-top: 2rem;
    }

    @media print {
      img {
        width: 250px;
      }
    }
  }
}

// When not in the console
main:not(.console) #footer {
  width: 100%;
  padding: 2rem;
  background: #202020;
  color: white;

  .title {
    font-weight: bold;
  }
}
</style>
