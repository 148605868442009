<template>
  <svg
    id="booster"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    data-name="Laag 1"
    viewBox="0 0 50 51.690004"
    version="1.1"
    sodipodi:docname="formulebooster-logo.svg"
    inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
    width="50"
    height="51.690002"
  >
    <metadata id="metadata121">
      <rdf:RDF>
        <cc:Work rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title>FormuleBooster</dc:title>
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <sodipodi:namedview
      id="namedview119"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1"
      objecttolerance="10"
      gridtolerance="10"
      guidetolerance="10"
      inkscape:pageopacity="0"
      inkscape:pageshadow="2"
      inkscape:window-width="1320"
      inkscape:window-height="907"
      showgrid="false"
      inkscape:zoom="4.7385338"
      inkscape:cx="112.8995"
      inkscape:cy="31.376661"
      inkscape:window-x="1760"
      inkscape:window-y="285"
      inkscape:window-maximized="0"
      inkscape:current-layer="booster"
      inkscape:snap-page="true"
    />
    <defs id="defs4"></defs>
    <title id="title6">FormuleBooster</title>
    <path
      id="path10"
      :class="'cls-0_' + mode"
      d="M 18.929688,0.2890625 17.320312,2.5 2.5097656,22.890625 0.43945312,25.740234 1.8007812,27.609375 17.339844,49 l 1.669922,2.220703 3,-1 24.90039,-8.089844 2,-0.660156 v -1.521484 -2.089844 a 2.36,2.36 0 0 0 -1.240234,-0.410156 2.4,2.4 0 0 0 -1.439453,0.480469 v 1.410156 l -1,0.310547 -24,7.779297 -1.201172,0.390624 -0.648438,-0.90039 L 4.4707031,26.449219 3.9609375,25.75 4.6992188,24.75 19.210938,4.75 20,3.6699219 21.400391,4.109375 44.859375,11.730469 46,12.099609 l 0.07031,7.259766 a 0.58,0.58 0 0 0 0.580079,0.570313 h 1.628906 a 0.57,0.57 0 0 0 0.570312,-0.580079 V 19.21875 c -0.0032,-0.03176 -6.48e-4,-0.06015 0,-0.08984 V 10.050781 L 45.910156,9.0507812 21.810547,1.2207031 Z"
      inkscape:connector-curvature="0"
    />
    <path
      id="path12"
      :class="'cls-1_' + mode"
      d="m 42.47,24.19 v 0 a 1.4,1.4 0 0 1 1.4,1.4 v 9.74 a 1.23,1.23 0 0 1 -1.23,1.23 H 42.3 A 1.23,1.23 0 0 1 41.07,35.33 V 25.6 a 1.4,1.4 0 0 1 1.4,-1.4 z"
      inkscape:connector-curvature="0"
      style="fill: #1b304f"
    />
    <path
      id="path14"
      :class="'cls-1_' + mode"
      d="m 38,28.32 v 0 a 1.4,1.4 0 0 1 1.4,1.4 v 5.62 a 1.23,1.23 0 0 1 -1.23,1.23 H 37.83 A 1.23,1.23 0 0 1 36.6,35.34 v -5.62 a 1.4,1.4 0 0 1 1.4,-1.4 z"
      inkscape:connector-curvature="0"
      style="fill: #1b304f"
    />
    <path
      id="path16"
      :class="'cls-1_' + mode"
      d="m 33.24,32.17 v 0 a 1.4,1.4 0 0 1 1.4,1.4 v 1.76 a 1.23,1.23 0 0 1 -1.23,1.23 h -0.34 a 1.23,1.23 0 0 1 -1.23,-1.23 v -1.75 a 1.4,1.4 0 0 1 1.4,-1.4 z"
      inkscape:connector-curvature="0"
      style="fill: #1b304f"
    />
    <path
      id="path30"
      :class="'cls-0_' + mode"
      d="m 46.539062,21.330078 a 0.44,0.44 0 0 0 -0.308593,0.13086 0.44,0.44 0 0 0 -0.121094,0.339843 v 14.339844 a 0.38,0.38 0 0 0 0.410156,0.429687 h 2.089844 c 0.32,0 0.480469,-0.139687 0.480469,-0.429687 V 21.679688 A 0.35,0.35 0 0 0 48.75,21.330078 Z"
      inkscape:connector-curvature="0"
    />
    <circle
      id="circle32"
      :class="'cls-2_' + mode"
      cx="47.16"
      cy="11.1"
      r="2.4000001"
      style="fill: #ef810a"
    />
    <circle
      id="circle34"
      :class="'cls-2_' + mode"
      cx="19.719999"
      cy="2.4000001"
      r="2.4000001"
      style="fill: #ef810a"
    />
    <circle
      id="circle36"
      :class="'cls-2_' + mode"
      cx="2.4000001"
      cy="25.290001"
      r="2.4000001"
      style="fill: #ef810a"
    />
    <circle
      id="circle38"
      :class="'cls-2_' + mode"
      cx="19.719999"
      cy="49.290001"
      r="2.4000001"
      style="fill: #ef810a"
    />
    <path
      id="path40"
      :class="'cls-2_' + mode"
      d="m 50,39.85 a 2.4,2.4 0 0 1 -2.4,2.4 2.55,2.55 0 0 1 -0.74,-0.12 2.41,2.41 0 0 1 -1.66,-2.28 c 0,-0.07 0,-0.14 0,-0.2 a 2.4,2.4 0 0 1 2.39,-2.2 2.36,2.36 0 0 1 1.34,0.41 2.39,2.39 0 0 1 1.07,1.99 z"
      inkscape:connector-curvature="0"
      style="fill: #ef810a"
    />
    <path
      id="path42"
      :class="'cls-2_' + mode"
      d="m 39.09,21.17 -1.78,-6.47 -5.58,3.74 2.28,0.85 A 33.78,33.78 0 0 1 31,26 a 16.69,16.69 0 0 1 -2.38,3 9.83,9.83 0 0 1 -3.1,2.1 8.23,8.23 0 0 1 -3.72,0.58 11.9,11.9 0 0 1 -3.8,-1 18.61,18.61 0 0 1 -3.51,-2 21.7,21.7 0 0 1 -3.09,-2.66 22.26,22.26 0 0 0 2.85,3 19.14,19.14 0 0 0 3.4,2.41 12.81,12.81 0 0 0 4,1.44 9.72,9.72 0 0 0 4.35,-0.24 11.68,11.68 0 0 0 3.89,-2.11 18.29,18.29 0 0 0 3,-3.13 36.15,36.15 0 0 0 4.05,-7 z"
      inkscape:connector-curvature="0"
      style="fill: #ef810a"
    />
  </svg>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
@Component
export default class FormuleBoosterLogo extends Vue {
  @Prop({ type: String, default: 'dark' })
  public mode!: string;
}
</script>

<style>
svg#booster {
  height: 50px;
  width: 50px;
}
.cls-0_light {
  fill: #fff !important;
}
.cls-1_light {
  fill: #fff !important;
}
.cls-2_light {
  fill: #ef810a !important;
}
.cls-3_light {
  fill: #fff !important;
}

.cls-0_dark {
  fill: #1b304f !important;
}
.cls-1_dark {
  fill: #1b304f !important;
}
.cls-2_dark {
  fill: #ef810a !important;
}
.cls-3_dark {
  fill: #fff !important;
}
</style>
