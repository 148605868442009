import { AppConfig, ModuleQuestion, Service } from '@app/models';
import { ConditionGroup, ConditionRow } from '@bcase/core';
import * as BCE from '@bcase/module-editor';
import { ModuleMetadata } from '@bcase/module-editor';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { firebase } from '../../utils/firebase';
import { store } from '../store';

@Module({ dynamic: true, store, name: 'module', namespaced: true })
export class ModuleModule extends VuexModule {
  private _loaded = false;
  private _config: AppConfig | null = null;
  private _modules: BCE.Module[] = [];
  private _module?: BCE.Reference = undefined;
  private _blocks: BCE.Block[] = [];
  private _block?: BCE.Reference = undefined;
  private _services: Service[] = [];

  public get active() {
    return this.all.filter(m => m.settings.active);
  }

  public get all() {
    return this._modules.map(module => {
      const orderDB = (module.metadata && module.metadata.order) || 0;
      const order = parseInt('' + orderDB, 10);

      return {
        ...module,
        id: module.id.split('@')[0],
        metadata: { ...(module.metadata || {}), order },
      };
    });
  }

  public get blocks() {
    return this._blocks.map(block => ({
      ...block,
      id: block.id.split('@')[0],
    }));
  }

  public get block(): BCE.Block | undefined {
    if (!this.selectedBlock) return undefined;

    const { id, version } = this.selectedBlock;
    const block = this.blocks.find(b => b.id === id && b.version === version);

    return block || undefined;
  }

  public get current() {
    return this._module && this.find(this._module);
  }

  public get hasMaxResearchPrice() {
    return !!this._config?.maximumResearchPrice;
  }

  public get metadata(): ModuleMetadata | undefined {
    return this.current && this.current.metadata;
  }

  public get maxResearchPrice() {
    return this._config?.maximumResearchPrice || Number.MAX_SAFE_INTEGER;
  }

  public get services(): Service[] {
    return this._services;
  }

  public get find() {
    return (ref: BCE.Reference | string): BCE.Module | undefined => {
      const { id, version } =
        typeof ref === 'string' ? { id: ref, version: 'v1' } : ref;

      const module = this.all.find(m => {
        return m.id === id && m.version === version;
      });

      return module || undefined;
    };
  }

  public get findDashboard() {
    return (type: string, target?: string): ModuleQuestion | undefined => {
      const t = target;
      const modules = t
        ? this.all.filter(m => m.category.split('+').find(c => c.startsWith(t)))
        : this.all;

      for (const module of modules) {
        const questions = module.metadata.question || {};

        for (const id of Object.keys(questions)) {
          const question = questions[id];
          if (question.metadata && question.metadata.dashboard === type)
            return question;
        }
      }

      return undefined;
    };
  }

  public get getNextBlock() {
    return (ctx: any) => {
      // console.log(ctx);
      if (!this.current) return undefined;
      if (!this.selectedBlock) return this.current.flow.start;

      // console.groupCollapsed('condition');
      const next = this.current.flow.blocks[this.selectedBlock.id];
      for (const option of next || [])
        if (checkCondition(ctx, option.condition)) return option;
      // console.groupEnd();

      return undefined;
    };
  }

  public get selectedModule() {
    return this._module;
  }

  public get selectedBlock() {
    return this._block;
  }

  @Action({ rawError: true })
  public async bind(module?: BCE.Reference) {
    // Bind modules
    if (!this._loaded) {
      this.LOADED(true);
      await firebase.bind(this, '_modules', firebase.col('module'));
      await firebase.bind(this, '_services', firebase.col('service'));
      await firebase.bind(this, '_config', firebase.doc('app/config'));
    }

    if (!module) return;

    if (this.current) {
      const { id, version } = this.current;

      // Skip if already bound to the requested module
      if (id === module.id && version === module.version) return;
      // Blocks of another module are currently bound, unbind first
      else await firebase.unbind(this, '_blocks');
    }

    // Bind blocks
    await firebase.bind(this, '_blocks', getModuleBlockRef(module));
  }

  @Action({ rawError: true })
  public async duplicate(payload: { ref: BCE.Reference; user: string }) {
    const template = this.find(payload.ref);
    if (!template) return;

    const module: BCE.Module = {
      ...template,
      id: firebase.generateId(),
      version: 'v1',
      name: `Copy: ${template.name}`,
      settings: {
        active: false,
        createdAt: new Date().toISOString(),
        createdBy: payload.user,
        locked: false,
      },
      metadata: { ...template.metadata, question: {} },
    };

    firebase.runTransaction(async transaction => {
      const templates = await getModuleBlockRef(payload.ref)
        .get()
        .then(snap => firebase.toData<BCE.Block>(snap));

      const blocks = templates.map<BCE.Block>(b => ({
        ...b,
        elements: b.elements.map(e => ({ ...e, id: firebase.generateId() })),
        settings: { linked: [`${module.id}@${module.version}`] },
      }));

      transaction.set(getModuleRef(module), module);
      for (const block of blocks)
        transaction.set(getBlockRef(module, block), block);
    });
  }

  @Action({ rawError: true })
  public async unbind() {
    const unbindModules = firebase.unbind(this, '_modules');
    const unbindBlocks = firebase.unbind(this, '_blocks');
    const unbindServices = firebase.unbind(this, '_services');
    await Promise.all([unbindModules, unbindBlocks, unbindServices]);
  }

  @Action({ rawError: true })
  public async load(
    payload: { module?: BCE.Reference; block?: BCE.Reference } = {}
  ) {
    const { module, block } = payload;
    await this.bind(module);

    // Select module
    if (module) {
      const selected =
        !!this.selectedModule &&
        module.id === this.selectedModule.id &&
        module.version === this.selectedModule.version;

      if (!selected) this.SELECT_MODULE(module);
    }

    // Select block
    if (block) {
      const selected =
        !!this.selectedBlock &&
        block.id === this.selectedBlock.id &&
        block.version === this.selectedBlock.version;

      if (!selected) this.SELECT_BLOCK(block);
    }
  }

  @Action({ rawError: true })
  public async createModule(payload: string) {
    const id = firebase.generateId();
    const version = 'v1';

    const empty = BCE.Module.empty();
    const module: BCE.Module = {
      ...empty,
      id,
      version,
      settings: { ...empty.settings, createdBy: payload },
      metadata: {
        core_values: '',
        license: [],
        order: 0,
        price: 0,
        product_service: '',
        question: {},
        structure: 'off-by-default',
      },
    };

    await getModuleRef({ id, version }).set(module);
    return module;
  }

  @Action({ rawError: true })
  public async createBlock(payload?: BCE.Reference) {
    if (!this.current) return;

    const id = payload ? payload.id : firebase.generateId();
    const version = payload ? payload.version : 'v1';

    const empty = BCE.Block.empty();
    const block: BCE.Block = {
      ...empty,
      id,
      version,
      settings: {
        ...empty.settings,
        linked: [`${this.current.id}@${this.current.version}`],
      },
    };
    await getBlockRef(this.current, { id, version }).set(block);
    return block;
  }

  @Action({ rawError: true })
  public async updateModule(payload: BCE.Module) {
    const { flow, ...rest } = payload;
    await getModuleRef(payload).set(rest, { merge: true });
  }

  @Action({ rawError: true })
  public async updateFlow(payload: BCE.ModuleFlow) {
    if (this.current)
      await getModuleRef(this.current).set({ flow: payload }, { merge: true });
  }

  @Action({ rawError: true })
  public async updateBlock(payload: BCE.Block) {
    if (!this.current) return;

    // Temporary quick-fix to remove undefined values..
    const value = JSON.parse(JSON.stringify(payload));
    await getBlockRef(this.current, payload).set(value);
  }

  @Action({ rawError: true })
  public async deleteModule(payload: BCE.Reference) {
    const blocks = await getModuleBlockRef(payload)
      .get()
      .then(snap => {
        return snap.empty ? [] : snap.docs.map(s => s.data() as BCE.Block);
      });

    // Delete module & blocks in batch
    const batch = firebase.batch();
    for (const block of blocks) batch.delete(getBlockRef(payload, block));
    await batch.delete(getModuleRef(payload)).commit();
  }

  @Action({ rawError: true })
  public async deleteBlock(payload: BCE.Reference) {
    if (this.current) await getBlockRef(this.current, payload).delete();
  }

  @Action({ rawError: true })
  public async activate(payload: BCE.Reference) {
    const module = this.find(payload);
    if (!module) return;

    await getModuleRef(payload).update({ 'settings.active': true });
  }

  @Action({ rawError: true })
  public async deactivate(payload: BCE.Reference) {
    const module = this.find(payload);
    if (!module) return;

    await getModuleRef(payload).update({ 'settings.active': false });
  }

  @Mutation
  public LOADED(payload: boolean) {
    this._loaded = payload;
  }

  @Mutation
  public SELECT_BLOCK(payload: BCE.Reference) {
    this._block = payload;
  }

  @Mutation
  public SELECT_MODULE(payload: BCE.Reference) {
    this._module = payload;
  }
}

const getBlockRef = (m: BCE.Reference, b: BCE.Reference) => {
  const ref = `/module/${m.id}@${m.version}/block/${b.id}@${b.version}`;
  return firebase.doc(ref);
};

const getModuleBlockRef = (m: BCE.Reference) => {
  return firebase.col(`/module/${m.id}@${m.version}/block`);
};

const getModuleRef = (m: BCE.Reference) => {
  return firebase.doc(`/module/${m.id}@${m.version}`);
};

function checkCondition(
  ctx: any,
  condition?: ConditionGroup | ConditionRow
): boolean {
  if (!condition || typeof condition === 'string') return true;
  return 'match' in condition
    ? checkConditionGroup(ctx, condition)
    : checkConditionRow(ctx, condition);
}

function checkConditionGroup(ctx: any, group: ConditionGroup) {
  return group.match === 'and'
    ? group.condition.every(c => checkCondition(ctx, c))
    : group.condition.some(c => checkCondition(ctx, c));
}

function checkConditionRow(ctx: any, row: ConditionRow) {
  switch (row.data) {
    case 'form':
      return checkConditionForm(ctx, row);
    case 'question':
      return checkConditionQuestion(ctx, row);
    default:
      console.warn('[condition] Unknown data:', row.data);
      return false;
  }
}

function checkConditionForm(ctx: any, row: ConditionRow) {
  const id = Object.keys(ctx)
    .filter(id => typeof ctx[id] === 'object')
    .find(id => !!ctx[id][row.property]);
  if (!id) return false;

  const value = ctx[id][row.property];
  const check = checkConditionValue.bind(undefined, row.check, row.value);
  if (typeof value === 'string') return check(value);
  if ('text' in value) return check(value.text);

  console.warn('[condition] Unknown form value:', value);
  return false;
}

function checkConditionQuestion(ctx: any, row: ConditionRow) {
  const value = Array.isArray(ctx[row.property])
    ? ctx[row.property][0]
    : ctx[row.property];
  const check = checkConditionValue.bind(undefined, row.check, row.value);

  if ('text' in value) return check(value.text);

  console.warn('[condition] Unknown question value:', value);
  return false;
}

function checkConditionValue(check: string, v1: any, v2: any) {
  // console.info(check, v1, v2);

  switch (check) {
    case 'equals':
      return v1 === v2;
    case 'not-equals':
      return v1 !== v2;
    default:
      console.warn('[condition] Unknown check:', check);
      return false;
  }
}
