<template>
  <div class="formule-booster-loader">
    <div class="dialog">
      <h3>{{ title }}</h3>
      <p>{{ message }}</p>
      <p></p>
      <div>
        <img :src="require('@app/asset/logo.png')" alt="" />
        <img :src="require('@app/asset/logo.png')" alt="" />
        <img :src="require('@app/asset/logo.png')" alt="" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class FormuleBoosterLoader extends Vue {
  @Prop({ type: String, default: '' })
  public title!: string;

  @Prop({ type: String, default: '' })
  public message!: string;

  @Prop({ type: String, default: 'light' })
  public mode!: string;
}
</script>

<style lang="scss">
.formule-booster-loader {
  @include elevate(24);
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  > div {
    @include elevate(24);
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 24px;
    width: calc(100vw - 64px);
    max-width: 560px;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    color: bce-color-on(dark);
    background-color: bce-color(dark, 700);
    text-align: center;
  }

  img {
    padding: 8px;
    height: 56px;
    color: bce-color();

    &:nth-child(1) {
      animation: logo-rotate 2s ease-in-out 0s infinite;
    }

    &:nth-child(2) {
      animation: logo-rotate 2s ease-in-out 0.1s infinite;
    }

    &:nth-child(3) {
      animation: logo-rotate 2s ease-in-out 0.2s infinite;
    }
  }

  @keyframes logo-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
