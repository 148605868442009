<template>
  <bce-root id="app" ref="bce-root">
    <AppHeader :is-mobile="isMobile" />

    <main :class="{ index: $route.name === 'index', console: $routeConsole }">
      <router-view />
      <AppFooter />
    </main>

    <template v-if="$user.complete">
      <bce-side-bar
        id="sidebar"
        position="right"
        :state="sidebar"
        style="z-index: 2"
        @state="!forcedSidebar && ui.setMenu($event.detail)"
      >
        <AppMenu :is-mobile="isMobile" />
      </bce-side-bar>

      <FilterSideBar v-if="$user.online" />
    </template>

    <FormuleBoosterLoader
      v-if="loader.title"
      :title="loader.title"
      :message="loader.message"
    />
    <bce-fab
      v-if="$route.name === 'index' || $route.name === 'account-license'"
      icon="fas:comment-dots"
    >
      <bce-button icon="fas:envelope" @click="contact = true">
        {{ $t('action-mail') }}
      </bce-button>
      <bce-button icon="fab:whatsapp" @click="onWhatsapp">
        {{ $t('action-whatsapp') }}
      </bce-button>
    </bce-fab>

    <dialog-contact
      v-if="contact"
      @submit="onContact"
      @discard="contact = false"
    />
  </bce-root>
</template>

<script lang="ts">
import { User } from '@app/models';
import Vue from 'vue';
import { Component, Watch, ProvideReactive } from 'vue-property-decorator';

import { UiModule } from './store/modules/ui-module';
import { getModule } from 'vuex-module-decorators';

import FormuleBoosterLoader from './components/0-formule-booster/formule-booster-loader.vue';
import AppFooter from './components/1-navigation/app-footer.vue';
import AppHeader from './components/1-navigation/app-header.vue';
import AppMenu from './components/1-navigation/app-menu.vue';
import DialogContact from './components/3-dialogs/dialog-contact.vue';
import FilterSideBar from './components/6-other/filter-side-bar.vue';

@Component({
  components: {
    AppFooter,
    AppHeader,
    AppMenu,
    DialogContact,
    FilterSideBar,
    FormuleBoosterLoader,
  },
})
export default class App extends Vue {
  @ProvideReactive() isMobile: boolean = false;
  private ui = getModule(UiModule);

  public contact = false;
  public loader = { title: '', message: '' };

  public get forcedSidebar() {
    const route = this.$route.name || '';
    const routes = ['index', 'complete-research', 'respondent'];
    return !this.$user.complete || routes.indexOf(route) >= 0;
  }

  public get sidebar() {
    const route = this.$route.name || '';
    const routes = ['index', 'complete-research', 'respondent'];
    return this.forcedSidebar ? 'closed' : this.ui.menu;
  }

  public get nonConsoleRoutes() {
    return ['index', 'about', 'method', 'dashboard-intro'];
  }

  public mounted() {
    this.getWidth();
    window.addEventListener('resize', this.getWidth);
    // window.addEventListener('beforeunload', this.beforeUnload);

    this.$root.$on('app:loader', this.onLoader);
  }

  public beforeDestroy() {
    window.removeEventListener('resize', this.getWidth);
    // window.removeEventListener('beforeunload', this.beforeUnload);
  }

  public destroyed() {
    this.$root.$off('app:loader', this.onLoader);
  }

  public beforeUnload(event: BeforeUnloadEvent) {
    // Note most browsers will just show a default message and ignore the
    // provided message, passing a message is still required to trigger the
    // default message.
    const message =
      this.$user && this.$user.online && !this.$user.complete
        ? 'Changes you made may not be saved.'
        : false;

    if (message) event.returnValue = message;
    return message;
  }

  @Watch('$user.data', { deep: true, immediate: false })
  public async watchUser() {
    await this.$user.ready;

    // Pull for token changes, max 10 times, with i seconds in between attemps
    for (let i = 1; i <= 10; i++) {
      if (!this.requireTokenRefresh()) break;
      await this.$user.refreshToken();
      await new Promise(res => setTimeout(res, 1000 * i));
    }
  }

  @Watch('$route.name')
  @Watch('$company.color')
  public watchCompanyColor() {
    const root = this.$root.$el as HTMLBceRootElement;
    if (this.nonConsoleRoutes.includes(this.$route?.name || ''))
      root.removeAttribute('style');
    else {
      try {
        root.accent('primary', this.$company.color);
      } catch {
        root.removeAttribute('style');
      }
    }
  }

  @Watch('$route.name')
  @Watch('$company.color2')
  public watchCompanyColor2() {
    const root = this.$root.$el as HTMLBceRootElement;
    if (this.nonConsoleRoutes.includes(this.$route?.name || ''))
      root.removeAttribute('style');
    else {
      try {
        root.accent('secondary', this.$company.color2);
      } catch {
        root.removeAttribute('style');
      }
    }
  }

  @Watch('$company.id')
  public async watchCompanyId() {
    if (this.$company.id || !this.$company.list.length) return;

    const [first] = this.$company.list;
    await this.$company.select(first.id);
  }

  public getWidth() {
    window.innerWidth <= 1024
      ? (this.isMobile = true)
      : (this.isMobile = false);
  }

  private requireTokenRefresh(): boolean {
    if (!this.$user.data) return false;

    // Retrieve token & database data
    const { admin, complete, company } = this.$user;
    const { status } = this.$user.data;

    // Check company
    if (company.length !== this.$user.data.company.length) return true;
    for (let i = 0; i < company.length; i++)
      if (company[i] !== this.$user.data.company[i]) return true;

    // Check status
    return !!status.admin !== !!admin || !!status.complete !== !!complete;
  }

  private onLoader(title = '', message = '') {
    this.loader = { title, message };
  }

  public onContact(data: any) {
    this.contact = false;
    this.$firebase.api.mail(data);
    this.$bce.message(this.$t('message-sent'), 2);
  }

  public onWhatsapp() {
    const { phone } = webpack.CONFIG.admin;
    window.open(`https://api.whatsapp.com/send?phone=${phone}`);
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"');

html,
body {
  height: 100%;
}

* {
  font-family: $font;
}

:root {
  @include bce-color-define-accent(map-get($accent, primary), primary);
  @include bce-color-define-accent(map-get($accent, secondary), secondary);
  @include bce-color-define-accent(map-get($accent, dark), dark);
  @include bce-color-define-surface(#fafafa #000, #fff);
}

bce-root {
  @include bce-color-default(primary);

  a {
    @include bce-color-default(secondary);
    display: inline-block;
  }

  table {
    .th-actions {
      width: 5ch;
    }

    .td-actions {
      padding: 4px 0 0;
      text-align: center;
    }
  }

  // @include media-medium {
  //   > ::-webkit-scrollbar {
  //     background-color: bce-color(light);
  //     width: 16px;
  //   }
  //
  //   > ::-webkit-scrollbar-track {
  //
  //   }
  //
  //   > ::-webkit-scrollbar-thumb {
  //     background-color: rgba(0, 0, 0, 0.6);
  //     border-radius: 16px;
  //     border: 2px solid #fff;
  //     margin: 0 2px;
  //   }
  //
  //   > ::-webkit-scrollbar-button {
  //     display: none;
  //   }
  // }

  // TODO: Move to core set
  bce-chip {
    margin: 0 2px;
  }

  // Undo bootstrap styling conflicting with bce-core
  line-height: normal;
  bce-switch *::after {
    box-sizing: initial;
  }

  [type='button'] {
    appearance: none;
  }

  // Undo own styling conflicting with bce-core
  bce-dialog {
    h3 {
      font-size: 1.17em;
    }

    p {
      margin: 1rem 0;
    }
  }
}

.progress {
  &.small {
    height: 5px;
    margin-bottom: 25px;

    > .progress-bar {
      background-color: bce-color(primary);
    }
  }
}

.pagination .page-item {
  &.active .page-link {
    z-index: 2;
  }

  .page-link {
    color: bce-color(secondary);

    &:focus {
      box-shadow: none;
    }
  }

  &.disabled .page-link {
    color: bce-color-on(surface, 3);
  }

  &.active .page-link {
    border-color: bce-color-on(secondary);
    background-color: bce-color(secondary);
    color: bce-color-on(secondary);
  }
}

.tabs {
  a {
    color: bce-color(secondary);
  }
}

main {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .bce-footer {
    flex: 0 1 auto;
  }

  > article {
    @include bce-color-default(surface);
    @include elevate(1, false);
    @include dropshadow;
    position: relative;
    display: block;
    border-radius: 4px;
    background-color: bce-color(surface);
    color: bce-color-on(surface);
    flex: 1 0 auto;

    &:not([compact]) {
      padding: 32px 16px;
      border-radius: 0 0 10px 10px;
    }
  }

  @include media-small {
    padding: 32px 16px 10px;

    > article:not([compact]) {
      border-radius: 5px;
    }
  }

  @include media-medium {
    padding: 40px 32px 28px;

    > article:not([compact]) {
      flex: 0 0 auto;
    }
  }
}

// Specifically the index page
main.index {
  padding: 0;
}

// Any page except the console pages
// main:not(.console) {
//   padding-bottom: 0;

//   article article {
//     padding: 0;
//     border: 0;
//   }
// }

[data-button-bar] {
  text-align: center;
  margin: -16px 0 8px;

  > bce-button {
    opacity: 1;

    > button:not(disabled) {
      color: bce-color(disable);

      &:hover {
        color: bce-color(primary);
      }
    }

    > button[disabled] {
      color: bce-color(primary);
    }
  }
}

[data-breadcrumbs] {
  padding-bottom: 20px;

  a {
    margin: 0px 2.5px;

    bce-button button {
      text-transform: none;
    }

    &.light {
      opacity: 0.6;
    }
  }
}

[data-table-header] {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
}

@include media-small {
  [data-table-header] {
    flex-flow: row nowrap;
    align-items: flex-end;
  }
}

bce-fab {
  z-index: 9;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.vdatetime {
  height: 48px;
  margin-bottom: 20px;

  &.no-margin {
    margin-bottom: 0;
  }

  input {
    flex: 1 0 100%;
    width: 100%;
    height: inherit;
    padding: 14px 12px;
    font-size: 0.9em;
    font-family: inherit;
    background-color: bce-color(surface);
    color: bce-color-on(surface);
    border: 1px solid bce-color-on(surface, 4);
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease-in-out;
    -webkit-appearance: none;
    cursor: inherit;
  }
}
</style>
