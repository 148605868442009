<template>
  <bce-dialog active @error="error" @submit="submit">
    <bce-input
      v-wmodel="form.name"
      validation="required"
      :label="$t('name')"
      compact
    />
    <bce-input
      v-wmodel="form.company"
      validation="required"
      :label="$t('company')"
      compact
    />
    <bce-input
      v-wmodel="form.email"
      validation="required|email"
      :label="$t('email')"
      compact
    />
    <bce-input
      v-wmodel="form.phone"
      validation="required|phone"
      :label="$t('phone')"
      compact
    />
    <bce-input
      v-wmodel="form.subject"
      validation="required"
      :label="$t('subject')"
      compact
    />
    <bce-input
      v-wmodel="form.message"
      validation="required"
      :label="$t('message')"
      type="textarea"
      rows="5"
      compact
    />

    <bce-button
      slot="action"
      style="opacity: 0.7"
      color="light"
      @click="discard"
    >
      {{ $t('cancel') }}
    </bce-button>
    <bce-button slot="action" class="mx-3" type="submit">
      {{ $t('send') }}
    </bce-button>
  </bce-dialog>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class DialogContact extends Vue {
  public form = {
    name: '',
    company: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  };

  public discard() {
    const dialog = this.$el as HTMLBceDialogElement;
    dialog.hide();
    this.$emit('discard');
  }

  public async error() {
    this.$bce.message(this.$t('message-not-sent'), 2);
  }

  public async submit() {
    await this.$firebase.api.mail({ ...this.form });
    this.$bce.message(this.$t('message-sent'), 2);
    this.discard();
  }
}
</script>
