<template>
  <router-link v-slot="{ navigate }" custom :to="to">
    <bce-link @click="navigate" @keypress.enter="navigate">
      <slot />
    </bce-link>
  </router-link>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class AppLink extends Vue {
  @Prop({ required: true })
  public to!: any;
}
</script>
