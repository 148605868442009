import { Permission } from '@app/models';
import i18next from 'i18next';
import Vue from 'vue';
import Router from 'vue-router';

import { company } from '../store/modules/company-module';
import { router as routerModule } from '../store/modules/router-module';
import { user } from '../store/modules/user-module';
import { routes } from './routes';

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, _, savedPosition) => {
    if (to.hash && document.querySelector(to.hash)) {
      // For some reason returning { selector: to.hash } doesn't result in the
      // element scrolling into view, so we do it manually.
      document.querySelector(to.hash)!.scrollIntoView();
      return { selector: to.hash };
    }

    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  routerModule.navigate(to);

  // Document title
  const title = location.href.startsWith('http://localhost')
    ? webpack.CONFIG.document.title.replace('[dev]', '[local]')
    : webpack.CONFIG.document.title;

  document.title = to.meta?.title
    ? `${title} - ${i18next.t(to.meta.title)}`
    : title;

  // Wait until user settings are initialized
  await user.ready;

  // Auto redirect to on-boarding flow
  if (
    user.online &&
    !user.complete &&
    !(to.name!.startsWith('on-boarding') || to.name === 'account-verified')
  )
    return next({ name: 'on-boarding' });

  // Auto redirect from on-boarding flow
  if (user.online && user.complete && to.name!.startsWith('on-boarding'))
    return next('/');

  // Select company using parameter
  const { company_id } = to.params;
  if (!!company_id && company.id !== company_id)
    await company.select(company_id);

  // Only available for developers, regular users are redirected to a 404 page.
  if (noDeveloper(to.meta)) {
    next('/404');
    return;
  }

  // Only available with correct authorization
  if (noAuth(to.meta) || noAdmin(to.meta) || noPermission(to.meta)) {
    const redirect = encodeURIComponent(to.fullPath);
    next('/unauthorized?redirect=' + redirect);
    return;
  }

  next();
});

router.afterEach(() => {
  // const container = document.querySelector('main');
  // if (container && 'scrollTo' in container) container.scrollTo(0, 0);
});

const noDeveloper = (meta?: { developer?: boolean }) => {
  return meta?.developer && !user.developer;
};

const noAuth = (meta?: { auth?: boolean }) => {
  return meta?.auth && !user.online;
};

const noAdmin = (meta?: { admin?: boolean }) => {
  return meta?.admin && !user.admin;
};

const noPermission = (meta?: { permission?: Permission[] }) => {
  return meta?.permission && !meta.permission.some(p => company.permission(p));
};
