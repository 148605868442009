import '@bcase/core/dist/bce/bce.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-datetime/dist/vue-datetime.css';
import './utils/validator';

import * as BceCore from '@bcase/core/loader';
import * as BceModuleEditor from '@bcase/module-editor/loader';

import BootstrapVue from 'bootstrap-vue';
import { Settings } from 'luxon';
import { register } from 'register-service-worker';
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import { Datetime } from 'vue-datetime';

import { fileManager } from './store/modules/storage-module';
import { store } from './store/store';
import Core from './utils/core';
import { firebase } from './utils/firebase';
import I18N from './utils/i18n';
import Icons from './utils/icons';
import { router } from './utils/router';
import App from './App.vue';

(async function main() {
  // Vue setup
  Vue.use(BootstrapVue);
  Vue.use(Core);
  Vue.use(Datetime);
  Vue.use(Icons);
  Vue.use(I18N);

  if (webpack.CONFIG.document.analytics) {
    Vue.use(
      VueGtag,
      { config: { anonymize_ip: true, id: webpack.CONFIG.document.analytics } },
      router
    );
  }

  Vue.config.productionTip = false;
  Vue.config.ignoredElements = [/^bce-/];
  Settings.defaultLocale = 'nl';

  // 3rd party async initialization
  await BceCore.applyPolyfills();
  await BceCore.defineCustomElements(window);
  await BceModuleEditor.defineCustomElements(window);

  window.BCE.generateId = firebase.generateId.bind(firebase);
  window.BCE.setColorScheme('light');
  window.BCE.file = fileManager;

  // Bootstrap Vue
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('bce-root');
})();

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      console.log('New content is available; please refresh.');
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
