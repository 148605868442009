<template>
  <bce-side-bar
    class="filter-side-bar"
    position="right"
    :state="open ? 'open' : 'closed'"
    style="z-index: 2"
  >
    <bce-button
      design="text"
      :disabled="!result.filters.length"
      @click="result.filterReset"
    >
      {{ $t('reset', { item: $t('filters') }) }}
    </bce-button>

    <div class="filters">
      <FilterSideBarItem
        v-if="result.target === 'kl'"
        :check="checkTarget"
        :filter="filterTarget"
        icon="fas:bullseye"
        :multi="true"
      />
      <FilterSideBarItem
        :check="checkModule"
        :filter="filterModule"
        icon="fas:shapes"
        :multi="true"
      />
      <FilterSideBarItem
        v-if="false"
        :check="checkGender"
        :filter="filterGender"
        icon="fas:user-friends"
      />

      <FilterSideBarItem
        v-for="filter of filters"
        :key="filter.id"
        :filter="filter"
        :hidable="true"
      />
      <FilterSideBarItem
        v-for="filter of respondentFilters"
        :key="filter.id"
        :check="checkRespondent"
        :filter="filter"
        icon="fas:users"
      />
    </div>
  </bce-side-bar>
</template>

<script lang="ts">
import { Filter } from '@app/models';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { ModuleModule } from '../../store/modules/module-module';
import { ResearchModule } from '../../store/modules/research-module';
import { ResultModule } from '../../store/modules/result-module';
import FilterSideBarItem from './filter-side-bar-item.vue';
import { StoredFilter } from '../../models/stored-filter';

const GENDER = ['male', 'female'];

@Component({
  components: { FilterSideBarItem },
})
export default class FilterSideBar extends Vue {
  public module = getModule(ModuleModule);
  public research = getModule(ResearchModule);
  public result = getModule(ResultModule);

  public get checkGender(): (filter: Filter) => StoredFilter['check'] {
    return filter => result => {
      const stored = this.result.filters.find(f => filter.id === f.id);
      if (!stored) return true;

      const options = stored.options.filter(o => o.active);
      if (!options.length) return true;

      const check = GENDER[options[0].value as number];
      return result.respondent.gender === check;
    };
  }

  public get checkModule(): (filter: Filter) => StoredFilter['check'] {
    return filter => result => {
      const stored = this.result.filters.find(f => filter.id === f.id);
      if (!stored) return true;

      const options = stored.options.filter(o => o.active);
      if (!options.length) return true;

      const modules = options.map(o => {
        const { module } = this.filterModule.element.value[o.value];
        return `${module.id}@${module.version}`;
      });

      return modules.indexOf(result.route.module) >= 0;
    };
  }

  public get checkRespondent(): (filter: Filter) => StoredFilter['check'] {
    return filter => result => {
      const stored = this.result.filters.find(f => filter.id === f.id);
      if (!stored) return true;

      const options = stored.options.filter(o => o.active);
      if (!options.length) return true;

      const value = result.respondentData[filter.label];
      return value && value === options[0].name;
    };
  }

  public get checkTarget(): (filter: Filter) => StoredFilter['check'] {
    return filter => result => {
      const stored = this.result.filters.find(f => filter.id === f.id);
      if (!stored) return true;

      const options = stored.options.filter(o => o.active);
      if (!options.length) return true;

      const targets = options.map(
        o => this.filterTarget.element.value[o.value],
      );

      const [id, version] = result.route.module.split('@');
      const module = this.module.find({ id, version });
      return (
        !!module &&
        module.category.split('+').some(c => targets.indexOf(c) >= 0)
      );
    };
  }

  public get filters() {
    const { current: research } = this.research;

    return [
      ...this.research.fsqFilters,
      ...this.result.availableFilters,
    ].filter(f => {
      if (!f.active) return false;

      if (
        !this.$user.admin &&
        ((research?.filters?.[f.id] == null && f.admin) ||
          research?.filters?.[f.id] === false)
      )
        return false;

      if (f.module.id === 'fsq') return true;

      const [id, version] = f.module.id.split('@');
      const module = this.module.find({ id, version });

      return module?.category
        .split('+')
        .find(c => c.startsWith(this.result.target));
    });
  }

  public get filterGender(): Filter {
    const genders = GENDER.map(text => ({
      name: this.$t('gender-' + text),
      text: this.$t('gender-' + text),
    }));

    return this.createFilter('filter-gender', this.$t('gender'), genders);
  }

  public get filterModule(): Filter {
    const modules = this.module.all
      .filter(m =>
        m.category.split('+').find(c => c.startsWith(this.result.target)),
      )
      .sort((a, b) => a.metadata.order - b.metadata.order)
      .map(m => ({
        module: m,
        name: m.name,
        text: `${m.metadata.order}. ${m.name}`,
      }));

    return this.createFilter('filter-module', this.$t('module'), modules);
  }

  public get filterTarget(): Filter {
    const targets = this.$FEATURES.target
      .filter(t => t.startsWith(this.result.target))
      .map(t => ({
        key: t,
        name: this.$t(`target-audience-${t}`),
        text: this.$t(`target-audience-${t}`),
      }));

    return this.createFilter(
      'filter-target',
      this.$t('target-audience'),
      targets,
    );
  }

  public get open() {
    const whitelist = this.result.filterRoutes;
    if (whitelist.indexOf(this.$route.name || '') < 0) return false;

    return this.result.showFilters;
  }

  public get respondentFilters() {
    return this.result.respondentFilters
      .map(filter => ({
        name: filter,
        options: this.findRespondentOptions(filter),
      }))
      .filter(filter => !!filter.options.length)
      .map(filter => {
        return this.createFilter(
          'respondent-' + filter.name,
          filter.name,
          filter.options.map(o => ({ text: o })),
        );
      });
  }

  public get respondents() {
    const { id } = this.$route.params;
    return this.research.respondents.filter(r => r.rid === id);
  }

  created() {
    this.module.bind();
    this.result.bind();
  }

  @Watch('open')
  public watchOpen() {
    const side = document.querySelector('#sidebar') as HTMLBceSideBarElement;
    if (this.open && side.state !== 'open') side.toggle(true);
  }

  public findRespondentOptions(filter: string) {
    const options = new Set<string>();
    for (const respondent of this.respondents)
      if (filter in respondent) options.add((respondent as any)[filter]);
    return Array.from(options).sort((a, b) => a.localeCompare(b));
  }

  private createFilter<T extends { text: string }>(
    id: string,
    label: string,
    value: T[],
  ): Filter {
    return {
      id,
      active: true,
      admin: false,
      element: { id: '', question: '', type: '', value },
      label,
      module: { id: '', name: '' },
    };
  }
}
</script>

<style lang="scss">
.filter-side-bar {
  width: 100%;

  &[state='closed'] {
    pointer-events: none;
  }

  .filters {
    position: relative;
    display: block;
    width: 100%;
  }
}
</style>
