import { User } from '@app/models';
import fb from 'firebase/app';
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';

import { firebase } from '../../utils/firebase';
import { store } from '../store';
import { company } from './company-module';

@Module({ dynamic: true, store, name: 'user', namespaced: true })
export class UserModule extends VuexModule {
  private _data: User | undefined = undefined;
  private _claims: User.Claims | undefined = undefined;
  private _list: User[] = [];
  private _skip_token_refresh = false;
  private _suppress_admin = false;

  public get admin() {
    return (
      !this._suppress_admin &&
      this._claims &&
      (this._claims.developer || this._claims.admin)
    );
  }

  public get cid() {
    return this._claims && this._claims.cid;
  }

  public get company() {
    return (this._claims && this._claims.company) || [];
  }

  public get complete() {
    return this._claims && this._claims.complete;
  }

  public get data() {
    return this._data;
  }

  public get developer() {
    return (
      this.admin && this.data && this.data.email.endsWith('@bce.consulting')
    );
  }

  public get find() {
    return (uid: string) => this._list.find(u => u.uid === uid);
  }

  public get id() {
    return this._data ? this._data.uid : '';
  }

  public get isRespondent() {
    return !this.data && !!this._claims;
  }

  public get list() {
    return this._list;
  }

  public get online() {
    return !!this._data;
  }

  public get ready() {
    return ready;
  }

  public get skipTokenRefresh() {
    return this._skip_token_refresh;
  }

  public get suppressAdmin() {
    return this._suppress_admin;
  }

  @Action({ rawError: true })
  public async finish() {
    if (!this.data || this.data.status.complete) return;
    const token = await firebase.account.complete();
    await this.signIn({ token });
  }

  @Action({ rawError: true })
  public async invitation(payload: { id: string }) {
    const token = await firebase.company.invitation(payload.id);
    await this.signIn({ token });
  }

  @Action({ rawError: true })
  public async quickStart(payload: { company: string; email: string }) {
    const { company, email } = payload;
    await firebase.account.quickStart(company, email);
  }

  @Action({ rawError: true })
  public async refreshToken() {
    if (this.skipTokenRefresh) return;
    const result = await firebase.refreshToken();
    if (!result) return;

    this.CLAIMS(result.claims);
    await company.unbind();
    await company.bind();
  }

  @Action({ rawError: true })
  public async signIn(payload: { code?: string; token?: string }) {
    this.SET_SKIP_TOKEN_REFRESH(true);

    const token =
      payload.token ||
      (payload.code && (await firebase.api.token(payload.code)));
    if (!token) return;

    const credentials = await firebase.signIn(token);
    await this.unbind();
    await this.bind(credentials.user!);
    this.SET_SKIP_TOKEN_REFRESH(false);
  }

  @Action({ rawError: true })
  public async signOut() {
    this.SET_SKIP_TOKEN_REFRESH(true);
    await this.unbind();
    await firebase.signOut();
    this.SET_SKIP_TOKEN_REFRESH(false);
  }

  @Action({ rawError: true })
  public async start(payload: { email: string; research: string }) {
    const token = await firebase.account.start(payload.email, payload.research);
    await this.signIn({ token });
  }

  @Action({ rawError: true })
  public setSuppressAdmin(payload: boolean) {
    this.SET_SUPPRESS_ADMIN(payload);
  }

  @Action({ rawError: true })
  public async bind(user: fb.User) {
    if (this.data) return;

    // Bind account data
    const ref = firebase.doc('user/' + user.uid);
    await firebase.bind(this, '_data', ref);

    // Retrieve claims from token
    const { claims } = await user.getIdTokenResult();
    this.CLAIMS(claims);

    // Admins get data of all accounts
    if (this.admin || (this.company && this.company.length)) {
      const userRef = firebase.col('user');
      const listRef = this.admin
        ? userRef
        : userRef.where('company', 'array-contains-any', this.company);
      await firebase.bind(this, '_list', listRef);
    }

    await company.bind();
  }

  @Action({ rawError: true })
  public async unbind() {
    if (!this.data) return;

    await firebase.unbind(this, '_data');
    await firebase.unbind(this, '_list');
    await firebase.unbind(this, '_authorizations');
    this.CLAIMS(null);

    await company.unbind();
  }

  @Mutation
  private CLAIMS(claims: any | null) {
    this._claims = claims ? claims : undefined;
  }

  @Mutation
  private SET_SKIP_TOKEN_REFRESH(skip: boolean) {
    this._skip_token_refresh = skip;
  }

  @Mutation
  private SET_SUPPRESS_ADMIN(suppress: boolean) {
    this._suppress_admin = suppress;
  }
}

export const user = getModule(UserModule);

const ready = new Promise<void>(res => {
  firebase.onAuth(u => {
    if (!u) user.unbind().then(res);
    else user.bind(u).then(res);
  });
});
