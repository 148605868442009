<template>
  <bce-header ref="bce-header" color="dark">
    <router-link
      :to="respondent.initialize ? { name: 'respondent' } : home"
      @click.native="scrollTop"
    >
      <img
        :class="{ index: index }"
        :src="index ? require('@app/asset/logo-color.png') : logo"
        alt="logo"
      />
    </router-link>

    <!-- <label>{{ title }}</label> -->

    <div v-if="false && $user.license === 'free'" class="license">
      <label>
        {{ licenseExpiration() }}
      </label>
    </div>

    <div style="flex: 1 1 auto" />
    <bce-nav v-if="index" class="menu-container">
      <AppLink v-if="$customerNameShort === 'lv'" to="/#intro">
        {{ $t('about') }}
      </AppLink>
      <AppLink to="/#solutions">{{ $t('solutions') }}</AppLink>
      <AppLink v-if="$customerNameShort === 'fb'" to="/about">
        {{ $t('about') }}
      </AppLink>
      <AppLink v-if="$customerNameShort === 'fb'" to="/method">
        {{ $t('method') }}
      </AppLink>
      <AppLink v-if="$customerNameShort === 'fb'" to="/dashboard-intro">
        {{ $t('dashboard-intro') }}
      </AppLink>
      <AppLink v-if="$customerNameShort === 'lv'" to="/#benefits">
        {{ $t('benefits') }}
      </AppLink>
      <AppLink v-if="$customerNameShort === 'lv'" to="/#stories">
        {{ $t('stories') }}
      </AppLink>
      <AppLink to="/#contact">{{ $t('contact') }}</AppLink>

      <bce-link v-if="!$user.online" :href="$signInURL">
        {{ $t('sign-in') }}
      </bce-link>
      <AppLink
        v-else
        :to="{ name: 'research-overview', params: { company_id: $company.id } }"
      >
        {{ $t('researches') }}
      </AppLink>
    </bce-nav>
    <div v-else class="header-right">
      <bce-menu
        v-if="$user.complete && !adminContentPage"
        class="company-button"
        color="light"
        :icon="$company.list.length > 1 ? 'fas:city' : 'fas:building'"
        :label="$company.data ? $company.data.name : '...'"
        label-collapse="small"
      >
        <bce-button
          v-for="(company, i) of $company.list"
          :key="company.id + i"
          :disabled="!canSwitchCompany"
          :icon="$company.id === company.id ? 'far:dot-circle' : 'far:circle'"
          :value="company.id"
          @click="$company.select(company.id)"
        >
          {{ company.name }}
        </bce-button>
      </bce-menu>
      <bce-nav-button v-if="$user.complete" @click="toggleMenu" />
    </div>
  </bce-header>
</template>

<script lang="ts">
import { Company } from '@app/models';
import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { RespondentModule } from '../../store/modules/respondent-module';
import { ResultModule } from '../../store/modules/result-module';
import { getDateAsString } from '../../utils/simple-functions';
import AppMenu from './app-menu.vue';
import AppLink from './app-link.vue';

@Component({
  components: { AppLink, AppMenu },
})
export default class AppHeader extends Vue {
  public respondent = getModule(RespondentModule);
  public result = getModule(ResultModule);
  public customLogo: any = '';

  @Prop({ type: Boolean })
  public isMobile!: boolean;

  public get home() {
    if (this.$user.online && !this.$user.complete)
      return '/on-boarding/license';

    return this.$user.online && this.$customerNameShort !== 'lv'
      ? `/c/${this.$company.id}/research`
      : '/';
  }

  public get index() {
    return (
      this.$route.name === 'index' ||
      this.$route.name === 'about' ||
      this.$route.name === 'dashboard-intro' ||
      this.$route.name === 'method' ||
      this.$route.name === 'quick-start'
    );
  }

  public get logo() {
    return !this.$company.logo || this.$route.name === 'index'
      ? require('@app/asset/logo.png')
      : this.$company.logo.url;
  }

  public get canSwitchCompany() {
    const blacklist = [
      'research-details-a',
      'research-details-b',
      'research-details-c',
      'research-details-c',
      'research-example',
      'research-invitation',
      'research-introduction',
      'research-formula',
      'research-respondents',
      'research-summary',
    ];

    return blacklist.indexOf(this.$route.name || '') < 0;
  }

  public get adminContentPage() {
    return (
      (this.$user && this.$user.admin && this.$route.name === 'index') ||
      this.$route.name === 'concept' ||
      this.$route.name === 'contact' ||
      this.$route.name === 'about'
    );
  }

  public get title() {
    const name = this.$route.name;
    return this.$i18n.exists(`${name}`) ? this.$t(`${name}`) : '';
  }

  public get cid() {
    return this.respondent.cid;
  }

  public get date() {
    return this.$user.data && this.$user.data.created.toDate();
  }

  public get username() {
    return this.$user.data &&
      this.$user.data.name.display &&
      this.$user.complete
      ? this.$user.data.name.display
      : '';
  }

  public toggleMenu(event: Event) {
    const check = this.result.filterRoutes;
    const route = this.$route.name || '';
    if (check.indexOf(route) < 0 || !this.result.showFilters) return;

    this.result.filterShow(false);
    event.preventDefault();
  }

  public licenseExpiration() {
    if (!this.date) return '';
    const seconds = 24 * 3600;
    const today = new Date() as any;
    const days = 30 - Math.floor((today - (this.date as any)) / 1000 / seconds);

    if (days <= 0) {
      // if (this.$route.name !== 'account-license') {
      //   this.$router.push('/account/license');

      //   this.$bce.alert(
      //     'Licentie verlopen',
      //     'Uw gratis licentie is verlopen. Als u verder gebruik wilt maken van deze dienst, dan dient u een betaalde licentie af te nemen.'
      //   );
      // }
      return this.$t('free-license-expired');
    } else {
      return this.$t('free-license-x-days-valid');
    }
  }

  public scrollTop() {
    const container = this.$root.$el.querySelector('main');
    if (container && 'scrollTo' in container) container.scrollTo(0, 0);
  }

  public async signOut() {
    await this.$user.signOut();
    this.$router.push('/');
  }
}
</script>

<style lang="scss">
bce-header {
  > a {
    height: 100%;
    width: auto;
  }

  img {
    height: 56px;
    padding: 6px 16px;

    &.index {
      max-height: 48px;
      margin: 4px 8px;
      padding: 6px;
    }
  }

  .header-right {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .company-button {
    --bce-con2-surface: rgba(255, 255, 255, 0.9);
    margin-top: 5px;

    bce-button {
      text-transform: none;
    }
  }

  bce-nav bce-link {
    width: 100%;
  }

  @include media-medium {
    bce-nav bce-link {
      width: initial;
    }
  }
}

@media print {
  bce-header {
    display: none;
  }
}
</style>
