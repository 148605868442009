import { Route } from 'vue-router';
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';

import { store } from '../store';

@Module({ dynamic: true, store, name: 'router', namespaced: true })
export class RouterModule extends VuexModule {
  private _params: Record<string, string> = {};

  public get params() {
    return this._params;
  }

  @Action({ rawError: true })
  public async navigate(payload: Route) {
    this.NAVIGATE(payload);
  }

  @Mutation
  private NAVIGATE(payload: Route) {
    this._params = { ...payload.params };
  }
}

export const router = getModule(RouterModule);
